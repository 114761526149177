<!-- eslint-disable -->
<template>
  <div class="page_box">
    <sb-headline title="数据字典"></sb-headline>
    <my-table ref="list" :data="listData" @page="pageHandler" :listLoading="listLoading">
      <template #header="params">
        <div class="search_box">
          <el-row :gutter="10">
            <el-col :span="4">
              <div class="search_item">
                <div>数据字典编码：</div>
                <el-input v-model="searchData.dataCode"></el-input>
              </div>
            </el-col>
            <el-col :span="4">
              <div class="search_item">
                <div>备注：</div>
                <el-input v-model="searchData.remark"></el-input>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="search_item">
                <div>状态：</div>
                <el-select v-model="searchData.status" clearable placeholder="请选择">
                  <el-option label="未启用" :value="0"></el-option>
                  <el-option label="启用" :value="1"></el-option>
                </el-select>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="10" style="margin-top: 10px;">
            <el-col :span="4" :offset="20" style="text-align: end;">
              <el-button @click="pageHandler(params, 1, 'reset')">重置</el-button>
              <el-button @click="pageHandler(params, 1)" type="primary">查询</el-button>
            </el-col>
          </el-row>
        </div>
      </template>
      <template #navBar>
        <div class="table_nav_bar">
          <el-button type="primary" @click="dictionaryAdd(row)">新增字典</el-button>
        </div>
      </template>
      <el-table-column prop="id" label="序号" fixed="left"></el-table-column>
      <el-table-column label="租户编码" fixed="left" prop="tenantCode" :min-width="140"></el-table-column>
      <el-table-column label="字典编码" fixed="left" prop="dataCode" :min-width="140"></el-table-column>
      <el-table-column label="字典值" prop="dataValue" :min-width="160"></el-table-column>
      <el-table-column label="字典描述" prop="dataDesc" :min-width="160"></el-table-column>
      <el-table-column label="语言" prop="language" :min-width="160"></el-table-column>
      <el-table-column label="备注" prop="remark" :min-width="160"></el-table-column>
      <el-table-column label="状态" prop="status" :min-width="120">
        <template slot-scope="scope">
          <div v-if="scope.row.status==1">启用</div>
          <div v-else>未启用</div>
        </template>
      </el-table-column>
      <el-table-column label="操作人" prop="updateUser" :min-width="100"></el-table-column>
      <el-table-column label="操作时间" prop="updateTime" :min-width="180"></el-table-column>
      <el-table-column label="操作" fixed="right" prop="" width="120">
        <template slot-scope="scope">
          <el-button class="handle_btn" type="text" size="mini" @click="tableHandle(1, scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </my-table>

    <!-- 新增品牌弹窗 -->
    <el-dialog title="编辑" class="insurance_dialog" :visible.sync="dictionaryAddVisible" width="40%">
      <el-form :model="dictionaryForm" ref="dictionaryForm" :rules="dictionaryAddRules" label-width="120px">
        <div class="insurance_dialog_module">
          <el-form-item label="字典编码：" prop="dataCode">
            <el-input v-model="dictionaryForm.dataCode" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item label="字典值：" prop="dataValue">
            <el-input v-model="dictionaryForm.dataValue" autocomplete="off" type="textarea" rows="2"></el-input>
          </el-form-item>


          <el-form-item label="字典描述：" prop="dataDesc">
            <el-input v-model="dictionaryForm.dataDesc" autocomplete="off" type="textarea" rows="2"></el-input>
          </el-form-item>


          <el-form-item label="备注：" prop="remark">
            <el-input v-model="dictionaryForm.remark" autocomplete="off" type="textarea" rows="4" ></el-input>
          </el-form-item>

          <el-form-item label="状态：" prop="status">
            <el-radio-group v-model="dictionaryForm.status">
              <el-radio :label="1">启用</el-radio>
              <el-radio :label="0">未启用</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dictionaryAddVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogConfirm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import FileUpload from "@/components/scope/file-upload.vue";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { FileUpload },
    data() {
    //这里存放数据
      return {
        listData: [],
        // 搜索数据
        searchData: {
          remark: '',
          status: null,
          dataCode: '',
        },
        listLoading: false,             //列表loading

        dictionaryAddVisible: false,

        dictionaryForm: {
          dataCode: '',
          dataValue: '',
          remark: "",
          status: "1",
          id: null,
          dataDesc: '',
        },
        dictionaryAddRules: {            //表单验证
          dataCode: [{ required: true, message: '请输入字典编码', trigger: 'blur' }],
          dataValue: [{ required: true, message: '请输入字典值', trigger: 'blur' }],
          dataDesc: [{ required: true, message: '请输入字典描述', trigger: 'blur' }],
          remark: [{ required: true, message: '请输入备注', trigger: 'blur' }],
          status: [{ required: true, message: '请选择状态', trigger: 'change' }],
        },
      };
    },
  filters: {

  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    pageHandler(params, num, type) {
      if(type == 'reset'){
        this.resetSearchData();
      }
      this.getBaseDictionaryList(params, num ? num : params.pageNum, params.pageSize);
    },
    // 新增品牌
    dictionaryAdd(row){
      this.dictionaryForm = {
        dataCode: '',
        dataValue: '',
        remark: "",
        status: 1,
        id: null,
      }
      this.dictionaryAddVisible = true;
    },
    // 弹窗确认
    dialogConfirm(){
      this.$refs.dictionaryForm.validate((valid) => {
        if (valid) {
          this.$store.dispatch('base/editDictionary',{
            ...this.dictionaryForm
          }).then(res => {
            this.$message.success('操作成功');
            this.$refs.list.init();
            this.dictionaryAddVisible = false;
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    // 表格内操作
    tableHandle(type, row) {
      switch (type) {
        case 1:
          this.dictionaryAddVisible = true;
          Object.keys(this.dictionaryForm).forEach(key => {
            this.dictionaryForm[key] = row[key]
          })
          break;
        default:
          break;
      }
    },

    // 重置搜索数据
    resetSearchData(e) {
      this.searchData = {
        remark: '',
        status: null,
        dataCode: '',
      }
    },

    getBaseDictionaryList(params,pageNum,pageSize) {
      this.listLoading = true;
      this.$store.dispatch('base/getDictionaryPageList',{
        ...this.searchData,
        pageNum,
        pageSize,
      }).then(res => {
        if(res.code == 0){
          this.listData = res.data.list;
          params.render(res.data.total);
        }
      })
      this.listLoading = false;
    },

  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  //生命周期 - 创建之前
  beforeCreate() {},
  //生命周期 - 挂载之前
  beforeMount() {},
  //生命周期 - 更新之前
  beforeUpdate() {},
  //生命周期 - 更新之后
  updated() {},
  //生命周期 - 销毁之前
  beforeDestroy() {},
  //生命周期 - 销毁完成
  destroyed() {},
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},
}
</script>
<style lang="scss" scoped>
  //@import url(); 引入公共css类
  .page_box {
    padding: 0 20px;
    .search_box {
      background: #fff;
      padding: 20px;
      margin-top: 20px;
    }
    .table_nav_bar{
      width: 100%;
      background: #fff;
      padding: 20px 20px 0 20px;
      box-sizing: border-box;
      .el-button{
        margin-right: 10px;
      }
    }
    .table_row_rule{
      word-break: break-all; /** 换行，长单词会分成两行显示 **/
      text-overflow: ellipsis;/** 隐藏文本省略号 ... **/
      display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
      -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
      -webkit-line-clamp: 1; /** 显示的行数 **/
      overflow: hidden;  /** 隐藏超出的内容 **/;
    }
  }
  .handle_btn{
    padding: 0;
  }
  .channel_item{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    >.el-input:nth-child(1){
      width: 260px;
    }
  }
  .channel_item:last-child{
    margin: 0;
  }
  .popularize_list_qrcode{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .qrcode_img_src{
    position: absolute;
    top: -10000%;
    z-index: -10000;
  }
  .search_item{
    display: flex;
    align-items: center;
    >div:first-child{
      flex-shrink: 0;
    }
  }
  .insurance_logo_link{
    width: 72px;
    height: 72px;
    display: block;
  }
  .insurance_dialog{
    ::v-deep .el-dialog__body{
      padding: 10px 20px !important;
    }
    .el-input{
      width: 50%;
    }
    .insurance_dialog_module{
      .dialog_module_title{
        font-weight: 500;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .title_line{
          width: 4px;
          height: 16px;
          background: #409EFF;
          margin-right: 5px;
          border-radius: 1px;
        }
      }
      .popup_table{
        .el-input{
          width: 100%;
        }
      }
    }
  }
  ::v-deep .el-table__header thead tr th:first-of-type, .el-table__body tbody tr td:first-of-type{
    padding: 0 !important;
  }
  ::v-deep .el-table__body tbody tr td:first-of-type {
    padding-left: 0px !important;
  }
</style>
